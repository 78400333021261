<template>
  <div class="error-assign-machine-table-component">
    <div class="table-first-row">
      <custom-input
        v-model="search"
        no-label
        border
        :placeholder="$t('error-assign-machine.users-table.searchPlaceholder')"
        class="search-input"
      >
        <template v-slot:leftIcon>
          <img
            :src="SearchIcon"
            alt="Icon"
          >
        </template>
      </custom-input>
    </div>
    <loading-spinner
      v-if="isLoading"
      :size="60"
    />
    <v-data-table
      v-else
      :headers="headers"
      :items="usersTableData"
      :search="search"
      :hide-default-footer="windowWidth <= 960 ? true : false"
      :disable-pagination="windowWidth <= 960 ? true : false"
      mobile-breakpoint="960"
      @click:row="rowClick"
    >
      <!-- eslint-disable -->
      <template v-slot:header.person="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.email="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.jobStatus="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.more="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>

      <template v-slot:item.person="{ item }">
        <div class="person">
          <span
            class="person-badge"
            :style="{backgroundColor: randomColor(item.id)}"
          >
            {{ item.firstName[0] }}{{ item.lastName[0] }}
          </span>
          {{ item.firstName }} {{ item.lastName }}
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div class="email">
          {{ item.email }}
        </div>
      </template>
      <template v-slot:item.jobStatus="{ item }">
        <div class="job-status">
          <span
            v-if="item.taskId"
            class="working"
          >
            {{ $t('error-assign-machine.users-table.working') }}
          </span>
          <span
            v-else
            class="free"
          >
            {{ $t('error-assign-machine.users-table.free') }}
          </span>
        </div>
      </template>
      <template v-slot:item.more="{ item }">
        <div
          v-if="$route.path.includes('select-user') || $route.path.includes('select-task')"
          class="more"
        >
          <img
            :src="ArrowRightIcon"
            alt="ArrowRightIcon"
            class="more-icon"
          >
        </div>
        <div
          v-else
          class="more"
          @click="showMore = item.id"
        >
          <img
            v-if="showMore === item.id"
            :src="MoreOnIcon"
            alt="MoreOnIcon"
            class="more-icon"
          >
          <img
            v-else
            :src="MoreOffIcon"
            alt="MoreOffIcon"
            class="more-icon"
          >
          <div
            v-if="showMore === item.id"
            v-click-outside="closeMore"
            class="more-container"
          >
            <ul class="items">
              <li
                v-if="item.taskId"
                class="item dark"
                @click="$router.push({ name: 'ErrorDetailMachine', params: { id: item.taskId }})"
              >
                <img
                  :src="ShowDetailIcon"
                  alt="Icon"
                  class="icon"
                >
                {{ $t('dashboard.error-table.table.showMore.detail') }}
              </li>
              <li
                v-if="item.taskId"
                class="item remove"
                @click.stop="handleRemoveUserModal()"
              >
                <img
                  :src="RemoveFromTaskIcon"
                  alt="Icon"
                  class="icon"
                >
                {{ $t('dashboard.error-table.table.showMore.remove') }}
              </li>
              <li
                v-else
                class="item"
                @click="selectUser(item)"
              >
                <!-- @click="$router.push({ name: 'selectTask', params: { userId: item.id }})" -->
                <img
                  :src="AddUserIcon"
                  alt="Icon"
                  class="icon"
                >
                {{ $t('dashboard.error-table.table.showMore.assign') }}
              </li>
            </ul>
          </div>
        </div>
      </template>
      <!-- eslint-enable -->
    </v-data-table>
    <remove-user-from-task-modal
      v-if="showRemoveUserModal"
      :remove-user-modal-data="removeUserModalData"
      @close-modal="showRemoveUserModal = false"
    />
  </div>
</template>

<script>
import CustomInput from '@/components/GlobalComponents/CustomInput.vue'
import SearchIcon from '@/assets/icons/search.svg'
import ArrowRightIcon from '@/assets/icons/chevron_right.svg'
import MoreOffIcon from '@/assets/icons/more_off.svg'
import MoreOnIcon from '@/assets/icons/more_on.svg'
import AddUserIcon from '@/assets/icons/add_user_error.svg'
import ShowDetailIcon from '@/assets/icons/show_detail_error.svg'
import RemoveFromTaskIcon from '@/assets/icons/remove_from_task.svg'
import LoadingSpinner from '@/components/GlobalComponents/LoadingSpinner'
import RemoveUserFromTaskModal from '@/components/GlobalComponents/Modals/RemoveUserFromTaskModal.vue'

export default {
  components: {
    CustomInput,
    LoadingSpinner,
    RemoveUserFromTaskModal
  },
  props: {
    usersTableData: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: this.$t('error-assign-machine.users-table.headers.person'), value: 'person' },
        { text: this.$t('error-assign-machine.users-table.headers.email'), value: 'email' },
        { text: this.$t('error-assign-machine.users-table.headers.jobStatus'), value: 'jobStatus' },
        { text: '', value: 'more', sortable: false }
      ],
      // badgeColors: [
      //   'EC8953',
      //   '00D8AD',
      //   'BF74D3',
      //   '0079FF'
      // ],
      colorCache: {},
      badgeColor: '',
      search: '',
      SearchIcon,
      ArrowRightIcon,
      MoreOffIcon,
      MoreOnIcon,
      showMore: null,
      AddUserIcon,
      ShowDetailIcon,
      RemoveFromTaskIcon,
      showRemoveUserModal: false,
      removeUserModalData: {},
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // randomColor () {
    //   const randomIndex = Math.floor(Math.random() * this.badgeColors.length)
    //   return `#${this.badgeColors[randomIndex]}`
    // },
    randomColor (id) {
      const r = () => Math.floor(256 * Math.random())
      return this.colorCache[id] || (this.colorCache[id] = `rgb(${r()}, ${r()}, ${r()})`)
    },
    closeMore () {
      this.showMore = null
    },
    handleRemoveUserModal () {
      this.removeUserModalData = this.usersTableData.find(item => item.id === this.showMore)
      this.removeUserModalData.color = this.colorCache[this.showMore]
      this.showRemoveUserModal = true
    },
    rowClick (row) {
      if (this.$route.path.includes('select-user') || this.$route.path.includes('select-task')) {
        this.$store.commit('ErrorAssign/SET_FIRSTNAME', row.firstName)
        this.$store.commit('ErrorAssign/SET_LASTNAME', row.lastName)
        this.$store.commit('ErrorAssign/SET_USERID', row.id)
        this.$store.commit('ErrorAssign/SET_COLOR', this.colorCache[row.id])
        this.$router.push({ name: 'selectTask', params: { userId: row.id } })
      }
    },
    selectUser (item) {
      this.$store.commit('ErrorAssign/SET_FIRSTNAME', item.firstName)
      this.$store.commit('ErrorAssign/SET_LASTNAME', item.lastName)
      this.$store.commit('ErrorAssign/SET_USERID', item.id)
      this.$store.commit('ErrorAssign/SET_COLOR', this.colorCache[item.id])
      this.$router.push({ name: 'selectTask', params: { userId: item.id } })
    },
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-assign-machine-table-component {
  width: 100%;
  max-width: 1108px;
  background-color: $primary-white;
  padding: 32px;

  div.table-first-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .search-input {
      max-width: 238px;
      font-size: 14px;
    }
  }

  // Table header
  .header-text {
    font-weight: 500;
    color: $primary-grey;
    letter-spacing: 0.4px;
  }

  // Table body
  div.person,
  div.email,
  div.job-status {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 56px;
    color: $primary-black;

    span.person-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: $primary-white;
      font-weight: 500;
      margin-right: 10px;
    }

    span.free {
      display: flex;
      align-items: center;
      padding: 2px 12px;
      color: $primary-black;
      font-weight: 500;
      font-size: 12px;
      background-color: #F5F5F5;
    }

    span.working {
      display: flex;
      align-items: center;
      padding: 2px 12px;
      color: $primary-white;
      font-weight: 500;
      font-size: 12px;
      background-color: $status-blue;
    }
  }
}

div.more {
  position: relative;
  cursor: pointer;

  img.more-icon {
    width: 24px;
    height: 24px;
  }

  div.more-container {
    position: absolute;
    right: 32px;
    top: -71px;
    width: 200px;
    padding: 8px 0;
    background-color: $primary-white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);

    ul.items {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      width: 100%;

      li.item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 12px 25px;

        &.dark {
          background-color: #F8F8F8;
        }

        &.remove {
          color: $status-red;
        }

        img.icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-assign-machine-table-component {
    padding: 31px 24px;

    div.table-first-row {
      justify-content: flex-start;

      .search-input {
        max-width: 100%;
      }
    }
  }

  div.more {
    div.more-container {
      width: 180px;

      ul.items {
        li.item {
          padding: 12px 19px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
