<template>
  <div class="error-assign-machine-table">
    <error-assign-machine-table-component
      :users-table-data="usersUpdated"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import ErrorAssignMachineTableComponent from './ErrorAssignMachineTableComponent.vue'

export default {
  name: 'ErrorAssignMachineTable',
  components: {
    ErrorAssignMachineTableComponent
  },
  data () {
    return {
      users: [],
      usersUpdated: [],
      isLoading: false
    }
  },
  created () {
    this.getData()
    this.getMachineErrors()
  },
  methods: {
    async getData () {
      try {
        this.isLoading = true
        const { data } = await this.$axios.get('/users')
        this.users = data.filter(x => x.role === 'MAINTAINER')
        console.log(this.users)
      } catch (err) {
        console.error(err)
      }
    },
    async getMachineErrors () {
      try {
        const response = await this.$axios.get('/machine-errors/?select=*,mainTask(user(*)),tasks(user(*)),machine(*),errorCategory(name),author(*),neededMaintainers,canBeClaimed&filter.finishedAt.isnull=true')
        const tasks = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].mainTask !== null) {
            tasks.push({
              userId: response.data[i].mainTask.user.id,
              taskId: response.data[i].id,
              errorName: response.data[i].machine.name
            })
          }
        }

        for (let x = 0; x < this.users.length; x++) {
          for (let y = 0; y < tasks.length; y++) {
            if (this.users[x].id === tasks[y].userId) {
              this.users[x].taskId = tasks[y].taskId
              this.users[x].errorName = tasks[y].errorName
            }
          }
        }
        console.log(response.data)
        this.usersUpdated = this.users
        console.log(this.usersUpdated)
        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-assign-machine-table {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 167px 167px 167px;
}

@media screen and (max-width: $mobile) {
  div.error-assign-machine-table {
    padding: 0;
  }
}
</style>
