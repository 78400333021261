<template>
  <div
    class="remove-user-modal-overlay"
    @click.stop="$emit('close-modal')"
  >
    <div
      class="modal"
      @click.stop
    >
      <div class="modal-header">
        <h5>
          {{ $t('error-assign-machine.remove-user-modal.heading') }}
        </h5>
      </div>
      <div class="modal-body">
        <ul class="data-list">
          <li class="data-item person">
            <span
              class="person-badge"
              :style="{backgroundColor: removeUserModalData.color}"
            >
              {{ removeUserModalData.firstName[0] }}{{ removeUserModalData.lastName[0] }}
            </span>
            {{ removeUserModalData.firstName }} {{ removeUserModalData.lastName }}
          </li>
          <li class="data-item task">
            <span class="error-name">
              {{ removeUserModalData.errorName }}
            </span>
            <span
              class="show-task"
              @click="$router.push({ name: 'ErrorDetailMachine', params: { id: removeUserModalData.taskId }})"
            >
              {{ $t('error-assign-machine.remove-user-modal.show-task') }}
            </span>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <span
          @click="$emit('close-modal')"
        >
          {{ $t('error-assign-machine.remove-user-modal.cancel') }}
        </span>
        <custom-button
          type="red-custom-button"
          :fullwidth="windowWidth <= 960 ? true : false"
        >
          {{ $t('error-assign-machine.remove-user-modal.submit') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'

export default {
  name: 'RemoveUserFromTaskModal',
  components: {
    CustomButton
  },
  props: {
    removeUserModalData: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.remove-user-modal-overlay {
  z-index: 99998;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  div.modal {
    z-index: 99999;
    width: 100%;
    max-width: 442px;
    height: 100%;
    max-height: 356px;
    background-color: $primary-white;
    padding: 40px 32px 32px 32px;

    div.modal-header {
      margin-bottom: 24px;
    }

    div.modal-body {
      ul.data-list {
        margin: 0;
        padding: 0;
        background-color: #F8F8F8;
        list-style-type: none;
        display: flex;
        flex-direction: column;

        li.data-item {
          display: flex;
          align-items: center;
          padding: 20px 24px;
          border-bottom: 1px solid $primary-lightgrey;
          font-size: 14px;
          letter-spacing: 0.25px;
          height: 72px;

          &.person {
            font-weight: 500;

            span.person-badge {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              color: $primary-white;
              margin-right: 12px;
            }
          }

          &.task {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span.error-name {
              width: 200px;
            }

            span.show-task {
              color: $primary-lightorange;
              font-weight: 500;
              font-size: 16px;
              cursor: pointer;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    div.modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;

      span {
        margin-right: 40px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.remove-user-modal-overlay {
    div.modal {
      margin: 0 24px;
      padding: 24px;
      max-height: 368px;

      div.modal-header {
        text-align: center;
      }

      div.modal-body {
        ul.data-list {
          li.data-item {
            padding: 16px;
            line-height: 21px;
            letter-spacing: 0.5px;
          }
        }
      }

      div.modal-footer {
        flex-direction: column-reverse;
        width: 100%;

        span {
          margin: 24.5px 0 0 0;
        }
      }
    }
  }
}
</style>
