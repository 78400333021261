<template>
  <div
    v-show="loading"
    class="lds-ring"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <div :style="[spinnerStyle, animDiv1]" />
    <div :style="[spinnerStyle, animDiv2]" />
    <div :style="[spinnerStyle, animDiv3]" />
    <div :style="[spinnerStyle]" />
  </div>
</template>

<script>
import calcPropertyValue from '@/helpers/calcPropertyValueForLoadingSpinner.js'

export default {
  name: 'LoadingSpinner',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 80
    },
    color: {
      type: String,
      default: '#FD6412'
    },
    duration: {
      type: String,
      default: '1.2s'
    }
  },
  data () {
    return {
      spinnerStyle: {
        borderWidth: `${this.size * 0.1}px`,
        borderColor: `${this.color} transparent transparent transparent`,
        animationDuration: this.duration
      }
    }
  },
  computed: {
    animDiv1 () {
      return calcPropertyValue('animationDelay', this.duration, -0.375)
    },
    animDiv2 () {
      return calcPropertyValue('animationDelay', this.duration, -0.25)
    },
    animDiv3 () {
      return calcPropertyValue('animationDelay', this.duration, -0.125)
    }
  }
}
</script>

<style lang="scss" scoped>
div.lds-ring {
  display: inline-block;
  position: relative;

  div {
    position: absolute;

    /* ratio: calc(64px / 80px) */
    width: 80%;
    height: 80%;

    /* ratio: calc(8px / 80px) */
    margin: 10%;
    border: 8px solid #fff;
    border-radius: 50%;
    border-color: #fff transparent transparent transparent;
    animation-name: lds-ring;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    animation-iteration-count: infinite;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
